<template>
  <!-- Add product (sidebar) -->
  <b-sidebar :id="id" :title="title" right backdrop shadow>
    <!-- <div class="col desc-column">
      <p>{{$t('contact-list-component.register.contact')}}</p>
    </div> -->
    <div class="create-contact-field col mb-0">
      <!-- Name -->
      <div class="input-group">
        <label for="">{{$tc('app.name', 1)}}*<!-- <span class="material-symbols-outlined"> info </span> --></label>
        <input v-model="form.first_name" type="text" class="form-control" required placeholder="" />
      </div>

      <!-- Last Name -->
      <div class="input-group">
        <label for="">{{$t('generic-str.lbl-last-name')}} <!-- <span class="material-symbols-outlined"> info </span> --></label>
        <input v-model="form.last_name" type="text" class="form-control" required placeholder="" />
      </div>

      <div class="input-group">
        <label>{{$t('generic-str.email')}}*</label>
        <input
          v-model="form.email"
          type="email"
          class="form-control"
          required
        />
      </div>

      <div class="input-group">
        <label>{{$t('generic-str.lbl-cellphone')}}*</label>
        <tel-input ref="mobile_number" required v-model="form.mobile_number"/>
        <!-- v-if="isShow" -->
        <div class="invalid-feedback" :class="{ show: invalidPhone }">
          {{$t('login-invite.invalid-phone')}}
        </div>
      </div>

      <div class="input-group">
        <label>{{$tc('generic-str.tel', 1)}}</label>
        <tel-input ref="phone" required v-model="form.phone"/>
      </div>

      <div class="input-group">
        <label>Empresa</label>
        <input
          v-model="form.company"
          type="text"
          class="form-control"
          required
        />
      </div>

      <!-- Gênero -->
      <div class="input-group">
        <label>{{$t('generic-str.gender')}}</label>
        <select-input
          label="text"
          :reduce="(gender) => gender.value"
          v-model="form.gender"
          :options="genders"
          :clearable="false"
        />
      </div>

      <div v-if="!group" class="input-group">
        <label>{{$t('sms.send-msg.tabs.groups.title')}}</label>
        <select-input
          multiple
          label="name"
          :reduce="(group) => group.id"
          v-model="form.groups"
          :options="groups"
          :clearable="false"
        />
      </div>

      <!-- Data de nascimento -->
      <div class="input-group">
        <label>{{$t('generic-str.birthdate')}}</label>
        <!-- <date-picker
          v-model="form.birthdate"
          :config="{
            format: 'DD/MM/YYYY',
            useCurrent: true,
            minDate: new Date('1900-01-01'),
            locale: 'pt',
            keepInvalid: true,
          }"
          v-b-tooltip.hover :title="form.birthdate"
          class="form-control input-date"
          :placeholder="$t('generic-str.date-hour')" /> -->
          <input
            class="form-control"
            placeholder=""
            type="date"
            v-model="form.birthdate">
      </div>

      <hr class="w-100">

      <div class="custom-field-wrapper mb-1">
        <span class="d-flex align-items-center field-title"><span class="material-symbols-outlined mr-1">playlist_add</span> Campo personalizado</span>
        <!-- <button @click="handleShowAddCustomField" class="btn btn-add-card">Add novo <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="heroicon add"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></button> -->
        <button v-if="customFields.length === 0" v-b-toggle.create-custom-field-sidebar class="btn btn-add-card">Add novo <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="heroicon add"><path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></button>
      </div>

      <div v-for="(customField, index) in customFields" :key="index" class="input-group">
        <label class="form-label">{{customField.name}}{{customField.required_field ? '*' : ''}}</label>
        <input
          class="form-control"
          type="text"
          :required="customField.required_field"
          :placeholder="customField.value ? customField.value : 'Campo customizado'"
          :ref="`customField-${index}`"
          v-model="customField.value">
      </div>

      <h5 class="100"><span class="material-icons-outlined">home</span>Endereço</h5>

      <div class="input-group">
        <label>{{$t('generic-str.zipcode')}}</label>
        <input
          @input="checkZipCode(form.zip)"
          v-zip
          ref="zipCode"
          v-model="form.zip"
          type="text"
          class="form-control"
          pattern="\w{9}"
          maxlength="9"
          placeholder="Ex: 77555-000"
          required
        />
      </div>

      <div class="input-group">
        <label>{{$t('generic-str.state')}}</label>
        <select
          v-model="form.state"
          type="text"
          class="form-control"
        >
          <option value="AC">Acre</option>
          <option value="AL">Alagoas</option>
          <option value="AP">Amapá</option>
          <option value="AM">Amazonas</option>
          <option value="BA">Bahia</option>
          <option value="CE">Ceará</option>
          <option value="DF">Distrito Federal</option>
          <option value="ES">Espírito Santo</option>
          <option value="GO">Goiás</option>
          <option value="MA">Maranhão</option>
          <option value="MT">Mato Grosso</option>
          <option value="MS">Mato Grosso do Sul</option>
          <option value="MG">Minas Gerais</option>
          <option value="PA">Pará</option>
          <option value="PB">Paraíba</option>
          <option value="PR">Paraná</option>
          <option value="PE">Pernambuco</option>
          <option value="PI">Piauí</option>
          <option value="RJ">Rio de Janeiro</option>
          <option value="RN">Rio Grande do Norte</option>
          <option value="RS">Rio Grande do Sul</option>
          <option value="RO">Rondônia</option>
          <option value="RR">Roraima</option>
          <option value="SC">Santa Catarina</option>
          <option value="SP">São Paulo</option>
          <option value="SE">Sergipe</option>
          <option value="TO">Tocantins</option>
          <option value="EX">Estrangeiro</option>
        </select>
      </div>

      <div class="input-group">
        <label>{{$t('generic-str.city')}}</label>
        <input
          v-model="form.city"
          type="text"
          class="form-control"
        />
      </div>
      <div class="input-group">
        <label>{{$t('app.address')}}</label>
        <input
          v-model="form.address"
          type="text"
          class="form-control"
          placeholder="Ex: Rua dos bobo"
        />
      </div>
      <div class="input-group">
        <label>{{$t('generic-str.neighborhood')}}</label>
        <input
          v-model="form.district"
          type="text"
          class="form-control"
          placeholder="Ex: Boa Vista"
        />
      </div>
      <div class="input-group">
        <label>{{$tc('generic-str.lbl-number', 1)}}</label>
        <input
          v-model="form.number"
          type="text"
          class="form-control"
        />
      </div>

      <div class="input-group">
        <label @click="fillForm()">{{$t('generic-str.complement')}}</label>
        <input
          v-model="form.complement"
          type="text"
          class="form-control"
        />
      </div>

      <h5><span class="material-icons-outlined">share</span>Social</h5>

      <div class="input-group">
        <label class="form-label">Website</label>
        <input
          class="form-control"
          type="text"
          placeholder="https://"
          v-model="form.website">
      </div>

      <div class="input-group">
        <label class="form-label">Facebook</label>
        <input
          class="form-control"
          type="text"
          placeholder="https://"
          v-model="form.facebook">
      </div>

      <div class="input-group">
        <label class="form-label">Instagram</label>
        <input
          class="form-control"
          type="text"
          placeholder="https://"
          v-model="form.instagram">
      </div>

      <div class="input-group">
        <label class="form-label">Twitter</label>
        <input
          class="form-control"
          type="text"
          placeholder="https://"
          v-model="form.twitter">
      </div>

      <div class="input-group">
        <label class="form-label">Linkedin</label>
        <input
          class="form-control"
          type="text"
          placeholder="https://"
          v-model="form.linkedin">
      </div>

      <hr>

      <h5><span class="material-icons-outlined">description</span>Descrição do contato</h5>

      <div class="input-group">
        <textarea class="form-control w-100" v-model="form.description" placeholder="Adicionar descrição"></textarea>
      </div>

      <button v-b-toggle="id" class="btn btn-outline-primary">{{$t('generic-str.cancel')}}</button>
      <button @click="create" :disabled="isSending" type="submit" :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }" class="btn btn-primary ml-auto">{{$t('generic-str.save')}}</button>
    </div>
  </b-sidebar>
  <!--/Add product (sidebar) -->
</template>

<script>
import 'bootstrap-vue/dist/bootstrap-vue.css';
import axios from '@/plugins/axios';
// import { VueTelInput } from 'vue-tel-input';
// import datePicker from 'vue-bootstrap-datetimepicker';
import ContactService from '@/services/contact.service';
import SelectInput from '@/components/SelectInput.vue';
import TelInput from '@/components/form/TelInput.vue';
import { mask } from 'vue-the-mask';
import moment from 'moment';
import Vue from 'vue';

const webApi = axios();

export default {
  components: {
    // VueTelInput,
    SelectInput,
    TelInput,
    // datePicker,
  },
  props: ['id', 'title', 'group', 'vcard'],
  directives: {
    mask,
    zip: {
      update(el) {
        // el.value = el.value.replace(/[-a-zA-Z]/g, ''); // Letters and capital letter, and special character: -
        if (!el.value) '';
        el.value = el.value.replace(/\D/g, '');
        el.value = el.value.replace(/(\d{5})(\d)/, '$1-$2');
      },
    },
  },
  data() {
    return {
      step: 0,
      groups: [],
      mobile_number: null,
      invalidPhone: false,
      genders: [
        {
          text: 'Feminino',
          value: 'female',
        },
        {
          text: 'Masculino',
          value: 'male',
        },
        {
          text: 'Prefiro não dizer',
          value: 'notsay',
        },
      ],
      bindProps: {
        mode: 'international',
        defaultCountry: 'BR',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: true,
        placeholder: this.$t('generic-str.lbl-cellphone'),
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        validCharactersOnly: true,
        preferredCountries: ['AU', 'BR'],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 25,
        wrapperClasses: '',
        inputClasses: 'form-control',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      cepInfos: [],
      form: {
        first_name: '',
        last_name: '',
        mobile_number: '',
        phone: '',
        company: '',
        email: '',
        groups: [],
        birthdate: '',
        gender: '',
        zip: '',
        state: '',
        city: '',
        address: '',
        number: '',
        complement: '',
        website: '',
        facebook: '',
        instagram: '',
        twitter: '',
        linkedin: '',
        description: '',
      },
      isSending: false,
      customFields: [],
    };
  },
  created() {
    this.fillForm();
    this.fetchGroups();
    this.fetchCustomFields();
  },
  methods: {
    fetchCustomFields() {
      axios().get('/contacts/custom-field').then((response) => {
        this.customFields = [];

        const customFields = this.customFields;

        response.data?.forEach((field) => {
          if (field.id && field.name) {
            customFields.push({
              account_id: field.account_id,
              appears_omni: field.appears_omni,
              deleted: field.deleted,
              description: field.description,
              id: field.id,
              name: field.name,
              required_field: field.required_field,
              sensitive_content: field.sensitive_content,
            });
          }
        });
        // this.selectedCustomFieldId = customFields[0].name;
        console.log('CUSTOM FIELD', customFields, response);
      });
    },
    checkZipCode(cep) {
      setTimeout(() => {
        if (cep.length === 9) {
          webApi.get(`https://viacep.com.br/ws/${cep}/json/`).then((response) => {
            console.log('CEP Response: ', response);
            const street = response.data.logradouro;
            const neighbourhood = response.data.bairro;
            const city = response.data.localidade;
            const uf = response.data.uf;

            this.form.address = (neighbourhood !== '' && neighbourhood !== undefined) ? `${street}` : '';
            this.form.district = neighbourhood;
            this.form.city = city;
            this.form.state = uf;
          });
        }
      }, 200);
      // console.log('CEP Teste');
    },
    fillForm() {
      if (this.vcard) {
        const keys = Object.keys(this.form);
        keys.forEach((key) => {
          this.form[key] = this.vcard[key];
        });
      }
    },
    numberChanges(rest) {
      if (rest.number.input.length > 0) {
        this.invalidPhone = !rest.isValid;
        if (rest.isValid) {
          this.mobile_number = rest.number.national;
          this.form.mobile_number = rest.number.input;
        }
      }
    },
    fetchGroups() {
      ContactService.getGroups().then(
        (groups) => {
          this.groups = groups;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    /* nextStep() {
      if (this.validate()) this.step = 1;
    }, */
    validate() {
      if (!this.form.first_name) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: 'Informe o primeiro nome',
          type: 'danger',
        });
        return false;
      }

      if (!this.form.email && !this.form.mobile_number) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: 'Celular ou email inválido',
          type: 'danger',
        });
        return false;
      }

      return true;
    },
    create() {
      if (this.validate()) {
        this.isSending = true;

        if (this.group) this.form.groups[0] = this.group.id;

        if (this.form.birthdate) {
          // ISO date format
          const date = moment(this.form.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD');

          if (date && date.indexOf('valid') === -1) this.form.birthdate = date;
        }
        /* eslint-disable */
        const custom_fields = {};
        /* eslint-enable */
        const cfs = this.customFields.map((s, index) => s);
        this.form = { ...this.form, ...{ custom_fields } };
        Object.keys(cfs).forEach((item) => {
          console.log(`CFS -> \nID: ${cfs[item].id}, \nVALUE: ${cfs[item].value}`);
          Vue.set(this.form.custom_fields, cfs[item].id, cfs[item].value);
        });
        // console.log('Contact items: ', this.form);
        ContactService.createContact(this.form).then(
          () => {
            // this.closeModal();
            this.toggleSidebar(this.id);
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: 'Contato criado',
              type: 'success',
            });
            this.$emit('sent');
            this.isSending = false;
          },
          (error) => {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error?.message || error,
              type: 'danger',
            });
            this.isSending = false;
          },
        );
      }
    },
    /* closeModal() {
      // Criado para fechar modal (vcardDEtailModal) caso tenha dados carregados de um vcard.
      this.hide();
      this.$emit('close-modal');
    }, */
    toggleSidebar(id) {
      this.$root.$emit('bv::toggle::collapse', id);
    },
  },
};
</script>
<style scoped lang="scss">
/* Sidebar add prod */
::v-deep .b-sidebar {
  /* input:invalid {
    background-color: #F6293414;
  } */
  background-color: var(--background-2) !important;
  width: 525px;
  small {
    color: var(--gray-font-color);
  }

  .b-sidebar-header {
    padding-left: 2rem;
    padding-right: 2rem;
    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }
  .b-sidebar-body {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.5rem;
  }
  header {
    >strong {
      order: -1;
    }
  }
  .desc-column {
    p {
      color: var(--gray-font-color);
    }
  }
  textarea, select, .form-control {
    border-radius: 8px;
  }

  .create-contact-field {
    display: flex;
    flex-wrap: wrap;

    h5 {
      font-size: 1rem;
    }

    button {
      border-radius: 8px;
      width: fit-content;
    }

    .input-group {
      display: flex;
      margin-bottom: 1.5rem;
      label {
        display: flex;
        align-items: center;
        gap: .5rem;
      }
      label:not(label.control) {
        width: 100%;
      }
      label.control ~ span {
        font-weight: 400;
        font-size: .875rem !important;
        margin-left: .5rem;
        color: var(--gray-font-color-6);
      }
      .v-select {
        width: 100%;
      }
    }
    .material-symbols-outlined {
      color: var(--gray-font-color);
    }
    .title-group {
      font-weight: 400;
      color: var(--gray-font-color-6);
      margin-bottom: 1rem;
    }
    .custom-field-list {
      width: 100%;
      li {
        margin-bottom: .5rem;
        color: var(--gray-font-color-2);
        font-weight: 400;
      }
    }
  }
}
::v-deep {
  .custom-field-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .field-title {
      color: var(--foreground);
      font-weight: 400;
    }
    button {
      margin-left: auto;
    }
  }
}
</style>
