<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog">
      <div v-if="data" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('generic-str.lbl-edit-contact') }}</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <span class="material-symbols-outlined"> close </span>
          </button>
        </div>
        <form @submit.prevent="save" class="form-horizontal">
          <div class="modal-body">
            <div class="form-body" v-if="step == 0">
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m">{{
                  $t('generic-str.lbl-first-name')
                }}</label>
                <div class="col-md-12">
                  <input
                    v-model="form.first_name"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m">{{
                  $t('generic-str.lbl-last-name')
                }}</label>
                <div class="col-md-12">
                  <input
                    v-model="form.last_name"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <div
                    class="custom-control custom-radio custom-control-inline"
                  >
                    <input
                      type="radio"
                      v-model="form.gender"
                      value="male"
                      checked
                      id="typeFeminino"
                      name="gender"
                      class="custom-control-input"
                    />
                    <label class="custom-control-label" for="typeFeminino"
                      >Feminino</label
                    >
                  </div>
                  <div
                    class="custom-control custom-radio custom-control-inline"
                  >
                    <input
                      type="radio"
                      v-model="form.gender"
                      value="male"
                      id="typeMasculino"
                      name="gender"
                      class="custom-control-input"
                    />
                    <label class="custom-control-label" for="typeMasculino"
                      >Masculino</label
                    >
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >Data de nascimento</label
                >
                <div class="col-md-12">
                  <input
                    v-model="form.birthdate"
                    type="text"
                    class="form-control"
                    v-mask="'##/##/####'"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m">{{
                  $t('generic-str.lbl-cellphone')
                }}</label>
                <div class="col-md-12">
                  <tel-input
                    ref="mobile_number"
                    v-if="isShow"
                    v-model="form.mobile_number"
                  />
                  <div class="invalid-feedback" :class="{ show: invalidPhone }">
                    {{ $t('login-invite.invalid-phone') }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >Email</label
                >
                <div class="col-md-12">
                  <input
                    v-model="form.email"
                    type="email"
                    class="form-control"
                  />
                </div>
              </div>
              <div v-if="!group" class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m">{{
                  $t('sms.send-msg.tabs.groups.title')
                }}</label>
                <div class="col-md-12">
                  <select-input
                    multiple
                    label="name"
                    :reduce="(group) => group.id"
                    v-model="form.groups"
                    :options="groups"
                    :clearable="false"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >Redes sociais</label
                >
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >Site</label
                >
                <div class="col-md-12">
                  <input
                    v-model="form.website"
                    type="email"
                    class="form-control"
                    placeholder="Ex.: https://meudominio.com"
                    @input="isChangeSite()"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"
                        >https://twitter.com/</span
                      >
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Nick name"
                      v-model="form.twitter"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"
                        >https://facebook.com/</span
                      >
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Nick name"
                      v-model="form.facebook"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"
                        >https://linkedin.com/</span
                      >
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Nick name"
                      v-model="form.linkedin"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"
                        >https://instagram.com/</span
                      >
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Nick name"
                      v-model="form.instagram"
                    />
                  </div>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  class="btn btn-secondary btn-modal-secundary"
                  @click="hide"
                >
                  <span class="to-left">Fechar</span>
                </button>
                <div
                  class="btn btn-primary btn-modal-primary to-right"
                  @click="step = 1"
                  v-if="!isBtnDisabled"
                >
                  <span class="to-left">Próximo</span>
                  <span class="material-symbols-outlined to-left"
                    >keyboard_arrow_right</span
                  >
                </div>
              </div>
            </div>
            <div class="form-body" v-if="step == 1">
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >CEP</label
                >
                <div class="col-md-12">
                  <input v-model="form.zip" type="text" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >Estado</label
                >
                <div class="col-md-12">
                  <select v-model="form.state" type="text" class="form-control">
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                    <option value="EX">Estrangeiro</option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >Cidade</label
                >
                <div class="col-md-12">
                  <input v-model="form.city" type="text" class="form-control" />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >Endereço</label
                >
                <div class="col-md-12">
                  <input
                    v-model="form.address"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >Número</label
                >
                <div class="col-md-12">
                  <input
                    v-model="form.number"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="control-label text-left col-md-12 text-left-m"
                  >Complemento</label
                >
                <div class="col-md-12">
                  <input
                    v-model="form.complement"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
              <div>
                <button
                  type="button"
                  class="btn btn-secondary btn-modal-secundary"
                  @click="step = 0"
                >
                  <span class="material-symbols-outlined to-left"
                    >keyboard_arrow_left</span
                  >
                  <span class="to-left">Voltar</span>
                </button>
                <button
                  :class="{
                    'qt-loader qt-loader-mini qt-loader-right': isSending,
                  }"
                  :disabled="isSending"
                  type="submit"
                  class="btn btn-primary btn-modal-primary to-right"
                >
                  <span class="to-left">Cadastrar</span>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer"></div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ContactService from '@/services/contact.service';
import Modal from '@/mixins/Modal';
import SelectInput from '@/components/SelectInput.vue';
import TelInput from '@/components/form/TelInput.vue';
import moment from 'moment';
// import { VueTelInput } from 'vue-tel-input';

export default {
  components: {
    SelectInput,
    TelInput,
    // VueTelInput,
  },
  mixins: [Modal],
  props: ['id', 'group'],
  data() {
    return {
      step: 0,
      groups: [],
      isBtnDisabled: false,
      isSending: false,
      mobile_number: null,
      invalidPhone: false,
      countryCode: '',
      form: {},
      bindProps: {
        mode: 'national',
        defaultCountry: 'BR',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: true,
        placeholder: this.$t('generic-str.lbl-cellphone'),
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        validCharactersOnly: true,
        preferredCountries: ['AU', 'BR'],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 25,
        wrapperClasses: '',
        inputClasses: 'form-control',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
    };
  },
  mounted() {
    this.fetchGroups();
  },
  methods: {
    validateUrl(value) {
      return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        value,
      );
    },
    isChangeSite() {
      if (this.form.website.length > 0) {
        this.isBtnDisabled = true;
        const validUrl = this.validateUrl(this.form.website);
        this.isBtnDisabled = !validUrl;
      } else {
        this.isBtnDisabled = false;
      }
    },
    fetchGroups() {
      ContactService.getGroups().then(
        (groups) => {
          this.groups = groups;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeCountry(e) {
      const tempNumber = this.form.mobile_number
        .replace(/\D/g, '')
        .split('(')[1];
      this.form.mobile_number = `${e.dialCode}${tempNumber}`;
    },
    save() {
      if (this.form.twitter) {
        this.form.twitter = `https://twitter.com/${this.form.twitter}`;
      }
      if (this.form.facebook) {
        this.form.facebook = `https://facebook.com/${this.form.facebook}`;
      }
      if (this.form.linkedin) {
        this.form.linkedin = `https://linkedin.com/${this.form.linkedin}`;
      }
      if (this.form.instagram) {
        this.form.instagram = `https://instagram.com/${this.form.instagram}`;
      }
      this.isSending = true;
      // const dataSend = this.data;
      if (this.form.birthdate) {
        this.form.birthdate = moment(this.form.birthdate, 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        ); // ISO date format
      }
      ContactService.editContact(this.form).then(
        () => {
          this.hide();
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('contact-component.update'),
            type: 'success',
          });
          this.$emit('sent');
          this.form = {};
          this.isSending = false;
        },
        (error) => {
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
              error.code
            }`,
            content: error.message,
            type: 'danger',
          });
          this.isSending = false;
        },
      );
    },
  },
};
</script>
